import axios from "axios";
import React from "react";
import { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { AiOutlineClose } from "react-icons/ai";
import Modal from "react-modal";

const customStyles = {
  overlay: { zIndex: 50 },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "transparent",
    border: "none",
  },
};

const Form = ({ modalIsOpen, setIsOpen }) => {
  const [data, setData] = useState({
    name: "",
    email: "",
    company: "",
    requirements: "",
  });
  function closeModal() {
    setIsOpen(false);
  }

  const onSubmit = () => {
    if (data?.name && data?.email && data?.company && data?.requirements) {
      axios
        .post("https://sightshark.onrender.com/", { ...data })
        .then((res) => {
          if (res.data.success) {
            setData({ name: "", email: "", company: "", requirements: "" });
            closeModal();
            toast.success("Connectors Request Form Submitted Successfully");
          }
        });
    } else {
      toast.error("Please fill all the details");
    }
  };

  return (
    <div className="z-50 relative">
      <Toaster />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="flex justify-between items-center p-6 lg:p-8 relative z-50 bg-[#030021] w-[90vw] lg:w-[60vw] rounded-3xl">
          <img
            src="/connectors_img.png"
            alt="Connectors img"
            className="w-2/5 object-contain p-5 lg:block hidden"
          />
          <div className="line h-[480px] w-[1px] bg-white/30 lg:block hidden"></div>
          <div className="w-full lg:w-1/2 flex-col items-center justify-center">
            <div className="flex items-center text-white justify-between w-full">
              <div></div>
              <h3 className="subSectionHeading font-semibold">
                Connector Request Form
              </h3>
              <AiOutlineClose
                onClick={(e) => {
                  closeModal();
                }}
                className="cursor-pointer"
              />
            </div>
            <div className="px-2 lg:px-5 w-full mt-6 flex flex-col items-center">
              <div className="flex flex-col w-full mb-5 subSection">
                <label htmlFor="client" className="text-white mb-1 subSection">
                  Name
                </label>
                <input
                  className="text-white px-4 mt-0.5 py-2 border subSection border-gray-700 rounded-md w-full outline-none bg-transparent"
                  id="name"
                  type="text"
                  placeholder="Name"
                  value={data?.name}
                  onChange={(e) => setData({ ...data, name: e.target.value })}
                />
              </div>
              <div className="flex flex-col w-full mb-5">
                <label htmlFor="client" className="text-white mb-1 subSection">
                  Email
                </label>
                <input
                  className="text-white px-4 mt-0.5 py-2 border border-gray-700 subSection rounded-md w-full outline-none bg-transparent"
                  id="email"
                  type="email"
                  placeholder="Email"
                  value={data?.email}
                  onChange={(e) => setData({ ...data, email: e.target.value })}
                />
              </div>
              <div className="flex flex-col w-full mb-5">
                <label htmlFor="client" className="text-white mb-1 subSection">
                  Company Name
                </label>
                <input
                  className="text-white px-4 mt-0.5 py-2 border border-gray-700 subSection rounded-md w-full outline-none bg-transparent"
                  id="company"
                  type="text"
                  placeholder="Company Name"
                  value={data?.company}
                  onChange={(e) =>
                    setData({ ...data, company: e.target.value })
                  }
                />
              </div>
              <div className="flex flex-col w-full mb-5">
                <label htmlFor="client" className="text-white mb-1 subSection">
                  Requirements
                </label>
                <textarea
                  placeholder="Requirements"
                  className="text-white px-4 mt-0.5 py-2 border subSection border-gray-700 rounded-md w-full outline-none bg-transparent"
                  value={data?.requirements}
                  onChange={(e) =>
                    setData({ ...data, requirements: e.target.value })
                  }
                />
              </div>
              <button
                onClick={(e) => {
                  onSubmit();
                }}
                className="bg-[#640DFB] text-white px-10 md:px-9 py-2 rounded-[100px] text-[14px] lg:text-[1.15rem] mt-2"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Form;
