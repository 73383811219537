import React, { useState } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import Form from "../Components/Form/Form";
import ConnectorImg from "../Components/ConnectorImg";
import DatabaseImg from "../Components/DatabaseImg";
import LandingSection from "../Components/Utils/LandingSection";
import CTA from "../Components/CTA";

function Connectors({ databaseData, connectorsData }) {
  const [open, setOpen] = useState(false);
  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <div className="w-full h-[auto] bg-[#030021] pt-[70px] lg:pt-[120px]">
      <Navbar />
      <LandingSection
        heading="Extract Your Data Easily with Our Connectors!"
        text="Explore data integration with our versatile connectors. From database to applications and APIs, effortlessly link your data sources."
        connectors={true}
      />
      <div className="h-[auto] flex flex-col gap-20 lg:px-0 px-2">
        <div className="text-white text-2xl text-center mx-auto lg:w-[60vw]">
          <p className="otherSection text-center text-[#CACACA] px-2 leading-normal">
            Discover a seamless data integration experience with our versatile
            collection
            <span className="font-[700] text-white ml-1">
              of connectors. From databases to applications and APIs
            </span>
            , our connectors are designed to link your data sources
            effortlessly.
          </p>
        </div>
        <div className="w-[85vw] lg:w-[85vw] mx-auto flex justify-center items-center">
          <ConnectorImg connectorsData={connectorsData} />
        </div>
      </div>
      <div className="h-[auto] flex flex-col gap-12 mt-32">
        <div className="flex flex-col gap-20 lg:px-0 px-2">
          <div className="flex flex-col justify-center items-center gap-2 lg:gap-4">
            <p className="text-center otherSectionHeading font-[500] text-white">
              Store Your Data in the most popular destinations!
            </p>
            <p className="text-[#CACACA] otherSection text-center">
              Choose from our range of storage options designed to fit your
              needs without the techy fuss, ensuring a smooth data experience.
            </p>
          </div>
          <div className="w-[85vw] lg:w-[85vw] mx-auto flex flex-col justify-center items-center">
            <DatabaseImg databaseData={databaseData} />
          </div>
        </div>
        <CTA
          heading={"Can't find the integration you’re looking for?"}
          text="Let us know and we’ll contact you with details!"
          button={"Request Connector"}
          onClick={() => setOpen(true)}
        />
        <Form modalIsOpen={open} setIsOpen={setOpen} />
      </div>
      <Footer />
    </div>
  );
}

export default Connectors;
