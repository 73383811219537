import React from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";

function PrivacyPolicy() {
  return (
    <div className="w-full h-[auto] bg-[#030020] pt-[70px] lg:pt-[120px]">
      <Navbar />
      <div className="flex flex-col w-[90vw] mx-auto gap-8">
        <div className="flex flex-row w-[90vw]  justify-center px-6 items-center gap-4 my-8">
          <p className="text-[1.2rem] lg:text-4xl font-[600]">
            <div className="flex gap-4 items-center">
              <h2 className="text-white font-[500]">Privacy Policy</h2>
            </div>
          </p>
        </div>
        <p className="otherSection inline text-grey  font-[400]">
          This Privacy Policy (“Policy”) explains how Sightshark (“Sightshark”,
          “Product”) provided by Prowiz Analytics (“Prowiz Analytics”, “we”,
          “us”, or “our”) collects, uses, stores, and discloses personal data
          when you access or use the Product (which may include our website,
          mobile app, dashboard, API, or other interfaces through which the
          Product is delivered, collectively the “Product Interface”). This
          Policy applies solely to the Sightshark product and does not cover any
          other services provided by Prowiz Analytics.
        </p>
        <div className="flex flex-col gap-4">
          <p className="font-[600] text-[1.4rem] inline text-gray-200">
            1. Product Controller
          </p>
          <p className="ml-1 lg:ml-1 text-[1.2rem] text-grey">
            Prowiz Analytics <br />
            SCO No. 28, Sector 27-C <br />
            Chandigarh - 160019, India <br />
            Email: info@prowiz.io <br />
            For any questions regarding data protection matters related to
            Sightshark, please contact our Privacy Team at:
            <br />
            Email: privacy@prowiz.io
          </p>
        </div>
        <div className="flex flex-col gap-4">
          <p className="font-[600] text-[1.4rem] inline text-gray-200">
            2. Scope of the Product Register
          </p>
          <p className="ml-1 lg:ml-1 text-[1.2rem] text-grey">
            The “Product User Register” comprises all customers, prospective
            users, and stakeholders who interact with Sightshark. The
            information collected in this register is used solely to deliver and
            enhance the services offered through Sightshark.
          </p>
        </div>
        <div className="flex flex-col gap-4">
          <p className="font-[600] text-[1.4rem] inline text-gray-200">
            3. Data We Collect and Our Purposes
          </p>
          <div className="flex flex-col gap-2">
            <span className="font-[600] text-[1.2rem] inline text-gray-200">
              {"A. Types of Personal Data Collected"}
            </span>
            <p className="inline ml-1 lg:ml-1 text-[1.2rem] text-grey">
              When you use Sightshark, we may collect, either directly or
              automatically, the following categories of personal data:
            </p>
            <ul className="list-disc ml-4 lg:ml-8 mt-4 space-y-4 otherSection">
              <div className="flex flex-row gap-2">
                <div className="w-[10px] h-[10px] mt-2 bg-white rounded-[50%]"></div>
                <li className="flex flex-col text-[1.2rem] text-grey">
                  <strong>Basic Identification Data:</strong> Full name, unique
                  user identifier, and preferred language.
                </li>
              </div>
              <div className="flex flex-row gap-2">
                <div className="w-[10px] h-[10px] mt-2 bg-white rounded-[50%]"></div>
                <li className="flex flex-col text-[1.2rem] text-grey">
                  <strong>Contact Information:</strong> Email address, phone
                  number, and physical mailing address.
                </li>
              </div>
              <div className="flex flex-row gap-2">
                <div className="w-[10px] h-[10px] mt-2 bg-white rounded-[50%]"></div>
                <li className="flex flex-col text-[1.2rem] text-grey">
                  <strong>Account and Transaction Data:</strong> Registration
                  details, login credentials, product usage history, and billing
                  or payment information.
                </li>
              </div>
              <div className="flex flex-row gap-2">
                <div className="w-[10px] h-[10px] mt-2 bg-white rounded-[50%]"></div>
                <li className="flex flex-col text-[1.2rem] text-grey">
                  <strong>Usage & Device Data:</strong>IP address, device
                  identifiers, browser type, operating system, and cookie data.
                  • Details about your interactions with the Product, such as
                  feature usage, session duration, and navigation patterns.
                </li>
              </div>
              <div className="flex flex-row gap-2">
                <div className="w-[10px] h-[10px] mt-2 bg-white rounded-[50%]"></div>
                <li className="flex flex-col text-[1.2rem] text-grey">
                  <strong>Feedback and Communication Data:</strong>Information
                  you provide via surveys, support requests, or direct
                  communications with our team.
                </li>
              </div>
            </ul>
          </div>
          <div className="flex flex-col gap-2">
            <span className="font-[600] text-[1.2rem] inline text-gray-200">
              {"B. Purposes of Processing"}
            </span>
            <p className="inline ml-1 lg:ml-1 text-[1.2rem] text-grey">
              We use your personal data to:
            </p>
            <ul className="list-disc ml-4 lg:ml-8 mt-4 space-y-4 otherSection">
              <div className="flex flex-row gap-2">
                <div className="w-[10px] h-[10px] mt-2 bg-white rounded-[50%]"></div>
                <li className="flex flex-col text-[1.2rem] text-grey">
                  <strong>Provide and Improve the Product: :</strong> Deliver
                  Sightshark’s services, tailor your user experience, and
                  continuously enhance product functionality.
                </li>
              </div>
              <div className="flex flex-row gap-2">
                <div className="w-[10px] h-[10px] mt-2 bg-white rounded-[50%]"></div>
                <li className="flex flex-col text-[1.2rem] text-grey">
                  <strong>Account Management and Customer Support</strong>{" "}
                  Manage your account, process transactions, and address your
                  support inquiries.
                </li>
              </div>
              <div className="flex flex-row gap-2">
                <div className="w-[10px] h-[10px] mt-2 bg-white rounded-[50%]"></div>
                <li className="flex flex-col text-[1.2rem] text-grey">
                  <strong>Analytics and Research:</strong> Analyze usage
                  patterns, conduct performance analytics, and gain insights to
                  drive product improvements.
                </li>
              </div>
              <div className="flex flex-row gap-2">
                <div className="w-[10px] h-[10px] mt-2 bg-white rounded-[50%]"></div>
                <li className="flex flex-col text-[1.2rem] text-grey">
                  <strong>Compliance and Security:</strong> Fulfill contractual
                  obligations, comply with legal requirements, and protect the
                  Product against fraud and unauthorized access.
                </li>
              </div>
              <div className="flex flex-row gap-2">
                <div className="w-[10px] h-[10px] mt-2 bg-white rounded-[50%]"></div>
                <li className="flex flex-col text-[1.2rem] text-grey">
                  <strong>Marketing and Communications:</strong> Inform you
                  about product updates, new features, and promotional offers
                  (where you have provided your consent).
                </li>
              </div>
            </ul>
          </div>
          <div className="flex flex-col gap-2">
            <span className="font-[600] text-[1.2rem] inline text-gray-200">
              {"C. Legal Basis for Processing"}
            </span>
            <p className="inline ml-1 lg:ml-1 text-[1.2rem] text-grey">
              We process your personal data on the following bases:
            </p>
            <ul className="list-disc ml-4 lg:ml-8 mt-4 space-y-4 otherSection">
              <div className="flex flex-row gap-2">
                <div className="w-[10px] h-[10px] mt-2 bg-white rounded-[50%]"></div>
                <li className="flex flex-row gap-4 text-[1.2rem] text-grey">
                  <strong>Consent:</strong> Where you have provided explicit
                  consent for optional features or communications.
                </li>
              </div>
              <div className="flex flex-row gap-2">
                <div className="w-[10px] h-[10px] mt-2 bg-white rounded-[50%]"></div>
                <li className="flex flex-row gap-4 text-[1.2rem] text-grey">
                  <strong>Contractual Necessity:</strong> To fulfill our
                  obligations under your user agreement with Sightshark.
                </li>
              </div>
              <div className="flex flex-row gap-2">
                <div className="w-[10px] h-[10px] mt-2 bg-white rounded-[50%]"></div>
                <li className="flex flex-row gap-4 text-[1.2rem] text-grey">
                  <strong>Legal Obligations:</strong> When required by
                  applicable law.
                </li>
              </div>
              <div className="flex flex-row gap-2">
                <div className="w-[10px] h-[10px] mt-2 bg-white rounded-[50%]"></div>
                <li className="flex flex-row gap-4 text-[1.2rem] text-grey">
                  <strong>Legitimate Interests:</strong> For product
                  improvement, security, analytics, and customer relationship
                  management, provided these interests are not overridden by
                  your rights.
                </li>
              </div>
            </ul>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <p className="font-[600] text-[1.4rem] inline text-gray-200">
            4. Sources of Data
          </p>
          <p className="ml-1 lg:ml-1 text-[1.2rem] text-grey">
            We obtain your personal data from:
          </p>
          <ul className="list-disc ml-4 lg:ml-8 mt-4 space-y-4 otherSection">
            <div className="flex flex-row gap-2">
              <div className="w-[10px] h-[10px] mt-2 bg-white rounded-[50%]"></div>
              <li className="flex flex-row gap-4 text-[1.2rem] text-grey">
                <strong>Direct Interactions:</strong> When you register for,
                use, or interact with Sightshark.
              </li>
            </div>
            <div className="flex flex-row gap-2">
              <div className="w-[10px] h-[10px] mt-2 bg-white rounded-[50%]"></div>
              <li className="flex flex-row gap-4 text-[1.2rem] text-grey">
                <strong>Automated Data Collection:</strong> Through cookies, log
                files, and similar tracking technologies as you use the Product
                Interface.
              </li>
            </div>
            <div className="flex flex-row gap-2">
              <div className="w-[10px] h-[10px] mt-2 bg-white rounded-[50%]"></div>
              <li className="flex flex-row gap-4 text-[1.2rem] text-grey">
                <strong>Third-Party Integrations:</strong> From partner systems
                or publicly available sources, where permitted by law.
              </li>
            </div>
          </ul>
        </div>
        <div className="flex flex-col gap-4">
          <p className="font-[600] text-[1.4rem] inline text-gray-200">
            5. Disclosure and International Transfers
          </p>
          <span className="font-[600] text-[1.2rem] inline text-gray-200">
            {"A. Sharing with Third Parties"}
          </span>
          <p className="ml-1 lg:ml-1 text-[1.2rem] text-grey">
            We may share your personal data with:
          </p>
          <ul className="list-disc ml-4 lg:ml-8 mt-4 space-y-4 otherSection">
            <div className="flex flex-row gap-2">
              <div className="w-[10px] h-[10px] mt-2 bg-white rounded-[50%]"></div>
              <li className="flex flex-row text-grey gap-2 text-[1.2rem]">
                <strong>Service Providers:</strong> Trusted third-party vendors
                (e.g., payment processors, analytics providers, hosting
                services) who process data solely for the purposes outlined in
                this Policy.
              </li>
            </div>
            <div className="flex flex-row gap-2">
              <div className="w-[10px] h-[10px] mt-2 bg-white rounded-[50%]"></div>
              <li className="flex flex-row gap-4 text-[1.2rem] text-grey">
                <strong>Automated Data Collection:</strong> Through cookies, log
                files, and similar tracking technologies as you use the Product
                Interface.
              </li>
            </div>
            <div className="flex flex-row gap-2">
              <div className="w-[10px] h-[10px] mt-2 bg-white rounded-[50%]"></div>
              <li className="flex flex-row gap-4 text-[1.2rem] text-grey">
                <strong>Legal or Regulatory Authorities:</strong> When required
                by law or to protect our rights, users, or the public.
              </li>
            </div>
          </ul>
          <p className="ml-1 lg:ml-1 text-[1.2rem] text-grey">
            Each third party is contractually bound to maintain the
            confidentiality and security of your data and to use it only as
            instructed by us.
          </p>
          <span className="font-[600] text-[1.2rem] inline text-gray-200">
            {"B. International Transfers"}
          </span>
          <p className="ml-1 lg:ml-1 text-[1.2rem] text-grey">
            If your data is transferred outside your jurisdiction (for example,
            from the EEA or India), we will ensure that appropriate safeguards
            (such as standard contractual clauses) are in place to protect your
            personal data.
          </p>
        </div>
        <div className="flex flex-col gap-4">
          <p className="font-[600] text-[1.4rem] inline text-gray-200">
            6. Data Security and Retention
          </p>
          <span className="font-[600] text-[1.2rem] inline text-gray-200">
            {"A. Security Measures"}
          </span>
          <p className="ml-1 lg:ml-1 text-[1.2rem] text-grey">
            We implement technical, organizational, and physical safeguards to
            protect your personal data, including:
          </p>
          <ul className="list-disc ml-4 lg:ml-8 mt-4 space-y-4 otherSection">
            <div className="flex flex-row gap-2">
              <div className="w-[10px] h-[10px] mt-2 bg-white rounded-[50%]"></div>
              <li className="flex flex-row gap-4 text-[1.2rem] text-grey">
                <strong>Encryption (in transit and at rest)</strong>
              </li>
            </div>
            <div className="flex flex-row gap-2">
              <div className="w-[10px] h-[10px] mt-2 bg-white rounded-[50%]"></div>
              <li className="flex flex-row gap-4 text-[1.2rem] text-grey">
                <strong>
                  Secure access controls and authentication mechanisms
                </strong>
              </li>
            </div>
            <div className="flex flex-row gap-2">
              <div className="w-[10px] h-[10px] mt-2 bg-white rounded-[50%]"></div>
              <li className="flex flex-row gap-4 text-[1.2rem] text-grey">
                <strong>Regular security assessments and monitoring</strong>
              </li>
            </div>
          </ul>
          <span className="font-[600] text-[1.2rem] inline text-gray-200">
            {"B. Data Retention"}
          </span>
          <p className="ml-1 lg:ml-1 text-[1.2rem] text-grey">
            We retain your personal data only for as long as necessary to
            achieve the purposes described above or as required by law.
            Typically, data in the Product User Register is kept for up to ten
            (10) years after the termination of your account, unless a longer
            retention period is mandated by legal obligations.
          </p>
        </div>
        <div className="flex flex-col gap-4">
          <p className="font-[600] text-[1.4rem] inline text-gray-200">
            7. Cookies and Tracking Technologies
          </p>
          <p className="ml-1 lg:ml-1 text-[1.2rem] text-grey">
            Sightshark uses cookies and similar technologies to:
          </p>
          <ul className="list-disc ml-4 lg:ml-8 mt-4 space-y-4 otherSection">
            <div className="flex flex-row gap-2">
              <div className="w-[10px] h-[10px] mt-2 bg-white rounded-[50%]"></div>
              <li className="flex flex-row gap-4 text-[1.2rem] text-grey">
                Enhance your user experience by remembering your preferences.
              </li>
            </div>
            <div className="flex flex-row gap-2">
              <div className="w-[10px] h-[10px] mt-2 bg-white rounded-[50%]"></div>
              <li className="flex flex-row gap-4 text-[1.2rem] text-grey">
                Analyze usage patterns and improve product performance.
              </li>
            </div>
            <div className="flex flex-row gap-2">
              <div className="w-[10px] h-[10px] mt-2 bg-white rounded-[50%]"></div>
              <li className="flex flex-row gap-4 text-[1.2rem] text-grey">
                Support optional marketing and communication activities (subject
                to your consent).
              </li>
            </div>
          </ul>
          <p className="ml-1 lg:ml-1 text-[1.2rem] text-grey">
            For details on managing cookies, please refer to our Cookie Policy.
          </p>
        </div>
        <div className="flex flex-col gap-4">
          <p className="font-[600] text-[1.4rem] inline text-gray-200">
            8. Your Rights
          </p>
          <p className="ml-1 lg:ml-1 text-[1.2rem] text-grey">
            Depending on your jurisdiction, you have rights regarding your
            personal data, including:
          </p>
          <ul className="list-disc ml-4 lg:ml-8 mt-4 space-y-4 otherSection">
            <div className="flex flex-row gap-2">
              <div className="w-[10px] h-[10px] mt-2 bg-white rounded-[50%]"></div>
              <li className="flex flex-row gap-4 text-[1.2rem] text-grey">
                <strong>Right of Access:</strong>Request a copy of your data.
              </li>
            </div>
            <div className="flex flex-row gap-2">
              <div className="w-[10px] h-[10px] mt-2 bg-white rounded-[50%]"></div>
              <li className="flex flex-row gap-4 text-[1.2rem] text-grey">
                <strong>Right to Rectification:</strong> Correct any inaccurate
                or incomplete data.
              </li>
            </div>
            <div className="flex flex-row gap-2">
              <div className="w-[10px] h-[10px] mt-2 bg-white rounded-[50%]"></div>
              <li className="flex flex-row gap-4 text-[1.2rem] text-grey">
                <strong>Right to Erasure:</strong> Request deletion of your data
                (subject to legal limits).
              </li>
            </div>
            <div className="flex flex-row gap-2">
              <div className="w-[10px] h-[10px] mt-2 bg-white rounded-[50%]"></div>
              <li className="flex flex-row gap-4 text-[1.2rem] text-grey">
                <strong>Right to Restriction:</strong> Limit the processing of
                your data.
              </li>
            </div>
            <div className="flex flex-row gap-2">
              <div className="w-[10px] h-[10px] mt-2 bg-white rounded-[50%]"></div>
              <li className="flex flex-row gap-4 text-[1.2rem] text-grey">
                <strong>Right to Object:</strong>Object to processing for
                specific purposes, such as direct marketing.
              </li>
            </div>
            <div className="flex flex-row gap-2">
              <div className="w-[10px] h-[10px] mt-2 bg-white rounded-[50%]"></div>
              <li className="flex flex-row gap-4 text-[1.2rem] text-grey">
                <strong>Right to Data Portability:</strong>Receive your data in
                a structured, machine-readable format.
              </li>
            </div>
            <div className="flex flex-row gap-2">
              <div className="w-[10px] h-[10px] mt-2 bg-white rounded-[50%]"></div>
              <li className="flex flex-row gap-4 text-[1.2rem] text-grey">
                <strong>Right to Withdraw Consent:</strong>Withdraw any consent
                you have provided for processing.
              </li>
            </div>
          </ul>
          <p className="ml-1 lg:ml-1 text-[1.2rem] text-grey">
            To exercise your rights or if you have any questions regarding our
            data practices, please contact us at privacy@prowiz.io. You may also
            lodge a complaint with your local data protection authority.
          </p>
        </div>
        <div className="flex flex-col gap-4">
          <p className="font-[600] text-[1.4rem] inline text-gray-200">
            9. Changes to This Policy
          </p>
          <p className="ml-1 lg:ml-1 text-[1.2rem] text-grey">
            We may update this Policy periodically. When we make material
            changes, we will update the “Last Updated” date and notify you via
            the Product Interface or by email. Your continued use of Sightshark
            after such changes constitutes your acceptance of the updated
            Policy.
          </p>
        </div>
        <div className="flex flex-col gap-4">
          <p className="font-[600] text-[1.4rem] inline text-gray-200">
            10. Contact Us
          </p>
          <p className="text-[1.2rem] text-grey">
            If you have any questions or concerns about this Product Privacy
            Policy or our data processing practices, please contact:
          </p>
          <p className="ml-1 lg:ml-1 text-[1.2rem] text-grey">
            Prowiz Analytics <br />
            SCO No. 28, Sector 27-C <br />
            Chandigarh - 160019, India <br />
            Email: info@prowiz.io <br />
            For privacy-specific inquiries: privacy@prowiz.io
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
