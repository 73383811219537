import React from "react";

const ConnectorImg = ({ connectorsData }) => {
  return (
    <div className="grid grid-cols-2 lg:grid-cols-4 md:grid-cols-6 gap-x-8 lg:gap-x-10 gap-y-8 lg:gap-y-10">
      {connectorsData?.map((e, i) => {
        return (
          <div
            key={i}
            className="video-box-shadow py-12 rounded-3xl hover:rounded-xl hover:scale-105 transition-all cursor-pointer flex flex-col text-white justify-center items-center lg:px-0 px-1"
          >
            <img src={e?.img} alt="Img" className="w-4/12 aspect-square" />
            <p className="text-sm mt-6 text-center">{e?.title}</p>
          </div>
        );
      })}
    </div>
  );
};

export default ConnectorImg;
