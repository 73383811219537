import React, { useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import Features from "../Components/Features";
import FAQ from "../Components/FAQ";
import ContactUs from "../Components/ContactUs";
import Footer from "../Components/Footer";
import "pure-react-carousel/dist/react-carousel.es.css";
import Partners from "../Components/Partners";
import LandingPage from "../Components/LandingPage";
import Line from "../Components/Utils/Line";
import Marquee from "../Components/Animations/AnimatedTestimonials";
import { FaStar } from "react-icons/fa";

function Home() {
  function scrollToDiv(id) {
    console.log("clicked");
    const div = document.getElementById(id);
    if (div) {
      div.scrollIntoView({ behavior: "smooth" });
    }
  }

  let data = [
    {
      name: "Johnson & Johnson",
      img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUpbv2BX1aOnMZMPoFgJRIKQz6jvni-MMJYA&s",
      desc: "The team's expertise in creating a customized Power BI dashboard, despite initial data ambiguity, resulted in a top-notch dashboard that sets new standards for functionality and insight.",
    },
    {
      name: "Proximo Spirits",
      img: "/testimonials/Proximo-Logo.png",
      desc: "SightShark delivered excellent Tableau dashboards and I enjoyed working with them. They were quick to respond to messages, met all deadlines, and did a great job of interpreting our requirements. ",
    },
    {
      name: "Advant Labs",
      img: "/testimonials/advant_logo1_black.png",
      desc: "SightShark excels in Google Looker, integrating data sources and creating intuitive, insightful visualizations. They tailored the dashboard to meet my business needs, providing the precise insights needed for informed decision-making.",
    },
    {
      name: "CMI Packaging",
      img: "/testimonials/cmi_packaging.jpg",
      desc: "SightShark's Tableau dashboards transformed our company's operations. They developed comprehensive dashboards for sourcing, inventory management, sales, and more, providing clear and actionable insights. Their expertise in Tableau has greatly enhanced our data visualization and decision-making processes.",
    },
    {
      name: "Dolphin Analytics",
      img: "/testimonials/dolphin_analytics.png",
      desc: "SightShark is an exceptional asset for companies seeking to enhance data presentation using Google Data Studio or other dashboards, consistently providing exceptional dashboard updates and improved visuals.",
    },
    {
      name: "Top Line Media",
      img: "/testimonials/logo-top-1.png",
      desc: "SightShark successfully created a complex Looker studio report, integrating various platforms and software, and successfully completed the project, recommending their services for future projects.",
    },
    {
      name: "Metropolis",
      img: "/testimonials/metropolis.jpg",
      desc: "SightShark provided a scalable, automated reporting solution for our marketing agency, integrating data from 10+ sources and creating tailored Looker Studio dashboards for 50+ clients. The workflow to create reports for new clients with minimal technical knowledge has greatly improved our operations.",
    },
  ];

  return (
    <div className="w-full overflow-x-hidden bg-[#030021] pt-[120px] flex flex-col justify-center items-center">
      <Navbar scrollToDiv={scrollToDiv} />
      <LandingPage scrollToDiv={scrollToDiv} />
      <div
        id="whatOffer"
        className="flex flex-col justify-center mt-10 lg:mt-20 mb-5 lg:mb-10 items-center"
      >
        <div className="flex flex-col lg:w-full w-11/12 justify-center items-center gap-4 lg:gap-8 mt-8">
          <h2 className="otherSectionHeading font-[500] text-white">
            What we offer?
          </h2>
          <p className="text-[#CACACA] mainSection text-center w-[90%] lg:w-[50%] px-2">
            With over 20+ Sources and 10+ Destinations, Sight Shark helps you
            automate and simplify the process of extraction, storage and
            reporting.
          </p>
        </div>
        <div className="flex justify-center items-center mt-5 lg:mt-2">
          <img
            src="/Sight Shark Animation.gif"
            alt="Img"
            className="w-full lg:w-[55vw]"
          />
        </div>
      </div>
      <div className="md:block hidden">
        <Line />
      </div>
      <div className="hidden lg:flex flex-col justify-center my-8 lg:my-16 items-center">
        <div className="basis-[10%] lg:w-full w-11/12 flex flex-col justify-center items-center gap-4 lg:gap-8 text-white ">
          <div className="text-center otherSectionHeading font-[500]">
            <p>What Sets Sight Shark Apart from the Rest?</p>
          </div>
          <div
            id="getStarted"
            className="mainSection text-center text-[#CACACA]"
          >
            <p className="hidden lg:block">
              {"Data to Insight’s journey is unnecessarily expensive and"}
              <br />{" "}
              {`complex
              to navigate.  Sight Shark is changing that.`}
            </p>
            <p className="lg:hidden block">
              {"Data to Insight’s journey is unnecessarily expensive and"}
              {`complex
              to navigate.  Sight Shark is changing that.`}
            </p>
          </div>
        </div>
        <div className="flex flex-row justify-center items-center mt-4 lg:mt-8">
          <img
            src={"/Position Map Animation.gif"}
            alt="Position map animation"
            className="max-w-[100vw] lg:w-[60vw]"
          />
        </div>
      </div>
      <Line />
      <div className="flex flex-col justify-center items-center gap-4 lg:gap-8 mt-8 lg:mt-16 mb-10 lg:mb-20">
        <h2 className="text-center lg:w-full w-11/12 otherSectionHeading font-[500] text-white">
          Why Choose Sight Shark ?
        </h2>
        <p className="mainSection lg:w-full w-11/12 text-[#CACACA] text-center hidden lg:block">
          Sight Shark has been purpose-built to empower Small and
          <br className="hidden lg:block" />
          Medium Businesses to make data-driven decisions!
        </p>
        <p className="mainSection text-[#CACACA] text-center block lg:hidden px-2">
          Sight Shark has been purpose-built to empower Small and Medium
          Businesses to make data-driven decisions!
        </p>
        <Features className="mt-4 lg:mt-8" />
        {/* <FeatureAnimation /> */}
      </div>
      <Line />
      <div className="flex flex-col justify-between items-center my-8 lg:my-16 gap-4 lg:gap-8 text-[#CACACA]">
        <p className="otherSectionHeading font-[500] text-white text-center lg:w-full w-11/12">
          Our Clients
        </p>
        <p className="lg:w-full mainSection w-11/12 text-center">
          Sight Shark enables
          <span className="text-white font-bold mr-1">
             100+ SMBs/Agencies to
          </span>
          gain a 360° perspective on Business Operations.
        </p>
        <Partners />
      </div>{" "}
      <Line />
      <div className="flex flex-col items-center justify-center mt-8 lg:mt-16 mb-10 lg:mb-36">
        <div className="flex flex-col gap-4 lg:gap-8">
          <p className="otherSectionHeading font-[500] text-white text-center">
            Testimonials
          </p>
          <p className="text-[#CACACA] mainSection text-center lg:w-full w-11/12 mx-auto">
            <span className="text-white font-bold mr-1">
               Don't take our words take theirs...
            </span>
            100+ businesses globally trust to get accurate, actionable data
            at fair prices
          </p>
        </div>
        {/* <div className="w-[85vw] mt-4 lg:mt-8 h-[auto] 2xl:h-[auto]">
          <Testimonals />
        </div> */}
        <div className="w-[85vw] mt-4 lg:mt-8 h-[auto] 2xl:h-[auto] text-white">
          {/* <AnimatedTestimonials /> */}
          <Marquee className="my-marquee" pauseOnHover repeat={3}>
            {data?.map((e, i) => {
              return <LetBlock data={e} key={i} />;
            })}
          </Marquee>
        </div>
      </div>
      <Line />
      <FAQ />
      <Line />
      <ContactUs />
      <Footer />
    </div>
  );
}

const LetBlock = ({ data }) => {
  return (
    <div
      id="testBlock"
      className="mx-5 w-[70vw] min-[500px]:w-[68vw] min-[600px]:w-[45vw] min-[850px]:w-[36vw] min-[900px]:w-[35vw] min-[1800px]:w-[30vw] py-5"
    >
      <div className="flex items-start relative z-40">
        <div className="bg-white w-1/5 min-[500px]:top-[25%] aspect-square flex items-center justify-center p-1.5 rounded-full">
          <img
            src={data?.img}
            className="rounded-full aspect-square object-contain"
          />
        </div>
        <div className="flex items-center ml-3 absolute min-[300px]:-top-5 min-[350px]:-top-4 min-[400px]:-top-1.5 min-[450px]:top-0 min-[550px]:top-2 min-[600px]:-top-6 min-[800px]:-top-2 min-[1000px]:-top-4 min-[1400px]:top-1 min-[1800px]:top-3 left-[22%]">
          {[1, 2, 3, 4, 5].map((e) => {
            return <FaStar key={e} className="text-yellow-600 text-2xl mr-2" />;
          })}
        </div>
      </div>
      <div className="bg-[#091441] flex flex-col items-end -mt-10 rounded-xl px-5 py-6 min-[300px]:h-[102vw] min-[350px]:h-[70vw] min-[400px]:h-[55vw] min-[450px]:h-[50vw] min-[500px]:h-[40vw] min-[700px]:h-[30vw] min-[850px]:h-[55vw] min-[900px]:h-[50vw] min-[1000px]:h-[36vw] min-[1200px]:h-[28vw] min-[1350px]:h-[22vw] min-[1500px]:h-[18vw] min-[1600px]:h-[15vw]">
        <h1 className="text-white font-semibold subSubSectionHeading text-end w-[75%] mb-1 -mt-2">
          {data?.name}
        </h1>
        <p className="text-[#CACACA] subSection">
          <span className="text-[16px] lg:text-2xl">"</span>
          {data?.desc}
          <span className="text-[16px] lg:text-2xl">"</span>
        </p>
      </div>
    </div>
  );
};

export default Home;
