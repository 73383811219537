import React, { useState, lazy, Suspense } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import LandingSection from "../Components/Utils/LandingSection";
const StepsComp = lazy(() => import("../Components/Agencies/StepsComp"));
const BenefitsComp = lazy(() => import("../Components/Agencies/BenefitsComp"));
const CounterComp = lazy(() => import("../Components/Agencies/CounterComp"));
const ConnectorComp = lazy(() =>
  import("../Components/Agencies/ConnectorComp")
);
const CTA = lazy(() => import("../Components/CTA"));

function Agencies({ databaseData, connectorsData }) {
  const [contactUsModal, setContactUsModal] = useState(false);
  let impact_in_numbers = [
    {
      number: 10,
      description: "data sources",
    },
    {
      number: 4,
      description: "years of daily historical data",
    },
    {
      number: 48,
      description: "hours time to deployment",
    },
    {
      number: 90,
      description: "reduction in reporting effort",
    },
  ];
  let report = {
    headings: [
      {
        title: "Overview",
        images: "/agency/step 1.png",
        text: "The Overview page summarizes overall performance metrics including leads, opportunities, and closed deals, along with key statistics such as number of campaigns run, visits, contacts, sessions, and bounce rate.",
      },
      {
        title: "Campaigns",
        images: "/agency/step 1.png",
        text: "The Campaigns page highlights campaign performance with metrics like open rate, conversion rate, number of contacts, and detailed email conversion statistics, including delivered emails, clicks, and conversion activity heatmap.",
      },
      {
        title: "Deals",
        images: "/agency/step 1.png",
        text: "The Deals page presents detailed information on deal stages, amounts, and trends over time, including metrics such as number of open deals, projected amounts, and average deal age. It also lists top won and lost deals by amount and provides reasons for lost deals.",
      },
      {
        title: "Team Performance",
        images: "/agency/step 1.png",
        text: "The Team Performance page evaluates the performance of individual team members based on number of deals, closed deals, closed amount, and close rate. It provides a comparative view of each team member's contributions and performance metrics.",
      },
    ],
  };

  return (
    <div className="w-full bg-[#030021] text-white pt-[90px] lg:pt-[120px]">
      <Navbar />
      <LandingSection
        heading="Boost Your Speed And Get Valuable Business Insights"
        text="With SightShark, convert your data into insightful  AI-powered reports as easily as the snap of your fingers"
        agencies={true}
      />
      <img src="/agency/landing.png" className="mx-auto" />
      <Suspense fallback={<p>Loading...</p>}>
        <CounterComp impact_in_numbers={impact_in_numbers} />
        <BenefitsComp />
        <StepsComp report={report} />
        <ConnectorComp connectorsData={connectorsData} />
        <CTA
          heading={"Manage your data efficiently, choose Sight Shark."}
          text={
            "Get with Sight Shark and master data management like no other."
          }
          button="Schedule a call"
          onClick={() => setContactUsModal(true)}
          setContactUsModal={setContactUsModal}
          contactUsModal={contactUsModal}
        />
      </Suspense>
      <Footer />
    </div>
  );
}

export default Agencies;
