import React from "react";
import { OrbitingCirclesDemo } from "../Animations/ConnectCircle";

const LandingSection = ({ heading, text, connectors, agencies }) => {
  return (
    <div
      className={`backgroundglitter relative overflow-x-hidden w-full h-[50vh] lg:h-[90vh] flex ${
        agencies ? "flex-col" : "flex-row"
      } justify-center items-center`}
    >
      <div className="flex flex-col items-center justify-center gap-4 lg:gap-8">
        <h1 className="mainHeading">{heading}</h1>
        <p className="text-[#CACACA] mainSection text-center w-11/12 lg:w-7/12 md:w-6/12">
          {text}
        </p>
      </div>
      <div className="w-[30vw] lg:block hidden absolute top-1/2 -translate-y-1/2 right-[-13vw]">
        {connectors && <OrbitingCirclesDemo />}
      </div>
      {agencies && (
        <button
          className="mainButton mt-5 lg:mt-20"
          onClick={() => {
            document.getElementById("whatOffer").scrollIntoView({
              behavior: "smooth",
              block: "end",
            });
          }}
        >
          Get Started
        </button>
      )}
    </div>
  );
};

export default LandingSection;
