import React from "react";
import LinkedIn from "../Assets/Footer/Linkedin.svg";
import { Link, useNavigate } from "react-router-dom";
import { IoIosCall } from "react-icons/io";
import { IoLocationSharp } from "react-icons/io5";

function Footer() {
  const navigate = useNavigate();

  return (
    <div className="w-full mt-16">
      <div className="hidden lg:flex py-20 footer-bg flex-row justify-between items-start">
        <div className="basis-[50%] flex flex-col justify-center items-center gap-4">
          <div className="flex flex-col justify-start items-start gap-8">
            <h1 className="otherSectionHeading text-white">Contact Us</h1>
            <div className="flex flex-row items-start text-newBlue gap-4 footerSections">
              <p>Customized Dashboard</p>
              <p>AI-Powered Insights</p>
              <p>Best price to value</p>
              <p>100% Secure</p>
            </div>
            <p className="text-newBlue footerSections">
              © 2024 SightShark. All rights reserved
            </p>
          </div>
        </div>
        <div className="basis-[30%] flex flex-row justify-around items-start gap-8 text-white">
          <div className="flex flex-col justify-start items-start gap-8">
            <p
              className="text-[1rem] 2xl:text-[1.15rem] cursor-pointer"
              onClick={() => {
                navigate("/connectors");
                window.scrollTo(0, 0);
              }}
            >
              Connectors
            </p>
            <p
              className="text-[1rem] 2xl:text-[1.15rem] cursor-pointer"
              onClick={() => {
                navigate("/case-studies");
                window.scrollTo(0, 0);
              }}
            >
              Case Studies
            </p>
            <p
              className="text-[1rem] 2xl:text-[1.15rem] cursor-pointer"
              onClick={() => {
                navigate("/reports");
                window.scrollTo(0, 0);
              }}
            >
              Reporting
            </p>
            <p
              className="text-[1rem] 2xl:text-[1.15rem] cursor-pointer"
              onClick={() => {
                navigate("/comparison");
                window.scrollTo(0, 0);
              }}
            >
              Compare
            </p>
            <p
              className="text-[1rem] 2xl:text-[1.15rem] cursor-pointer"
              onClick={() => {
                navigate("/privacy-policy");
                window.scrollTo(0, 0);
              }}
            >
              Privacy Policy
            </p>
          </div>
          <div className="flex flex-col justify-start items-start gap-8"></div>
        </div>
        <div className="basis-[30%] px-1 flex flex-col-reverse justify-start items-start gap-8 text-white text-xl">
          <Link to="https://www.linkedin.com/company/99902403">
            <div className="flex flex-row justify-center items-center gap-4 footerSections">
              <img src={LinkedIn} alt="" />
              <p className="text-[1rem] 2xl:text-[1.25rem]  text-center mt-1">
                LinkedIn
              </p>
            </div>
          </Link>
          <p className="footerSections">
            <span className="flex items-center">
              <IoIosCall className="mr-1 text-lg text-bgBlue" /> 9041679669
            </span>{" "}
            <br />
            <span className="flex items-start">
              <IoLocationSharp className="mr-1 mt-1 otherSection text-bgBlue" />{" "}
              Sco No 28, Second Floor, <br />
              Sector 27-C, Chandigarh 160019
            </span>
          </p>
        </div>
      </div>

      {/* // Mobile */}
      <div className="w-full lg:hidden flex footer-bg flex-col justify-between items-start px-8 py-12">
        <div className="flex flex-col justify-start items-start gap-4">
          <h1 className="otherSectionHeading text-white">Contact Us</h1>
          <div className="flex flex-col justify-between items-start subSection text-newBlue gap-2">
            <p>Customized Dashboard</p>
            <p>AI-Powered Insights</p>
            <p>Best price to value</p>
            <p>100% Secure</p>
          </div>
        </div>
        <div className="flex flex-col justify-start otherSection items-start gap-1 my-10 text-white">
          <p
            className="otherSection cursor-pointer"
            onClick={() => {
              navigate("/connectors");
              window.scrollTo(0, 0);
            }}
          >
            Connectors
          </p>
          <p
            className="otherSection cursor-pointer"
            onClick={() => {
              navigate("/case-studies");
              window.scrollTo(0, 0);
            }}
          >
            Case Studies
          </p>
          <p
            className="otherSection cursor-pointer"
            onClick={() => {
              navigate("/reports");
              window.scrollTo(0, 0);
            }}
          >
            Reporting
          </p>
          <p
            className="otherSection cursor-pointer"
            onClick={() => {
              navigate("/comparison");
              window.scrollTo(0, 0);
            }}
          >
            Compare
          </p>
        </div>
        <div className="flex subSection flex-col-reverse justify-start items-start gap-5 text-white text-xl">
          <Link to="https://www.linkedin.com/company/99902403">
            <div className="flex flex-row justify-center items-center gap-3">
              <img src={LinkedIn} alt="" />
              <p className="subSection text-center mt-1">LinkedIn</p>
            </div>
          </Link>
          <div className="subSection">
            <p className="flex items-center">
              <IoIosCall className="mr-1 otherSection" /> 9041679669
            </p>
            <p className="flex items-start">
              <IoLocationSharp className="mr-1 mt-1 otherSection" /> Sco No 28,
              Second Floor, <br />
              Sector 27-C, Chandigarh 160019
            </p>
          </div>
        </div>
        <p className="text-bgBlue subSection mt-10">
          © 2024 SightShark. All rights reserved
        </p>
      </div>
    </div>
  );
}

export default Footer;
