import React, { useState } from "react";
import { PopupModal } from "react-calendly";
import { useLocation, useNavigate } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";
import { AiOutlineClose } from "react-icons/ai";

function Navbar({ scrollToDiv }) {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const [contactUsModal, setContactUsModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const links = [
    // { title: "Agencies", link: "/agencies" },
    { title: "Connectors", link: "/connectors" },
    { title: "Case Studies", link: "/case-studies" },
    { title: "Reporting", link: "/reports" },
    { title: "Compare", link: "/comparison" },
  ];

  return (
    <div className="w-full">
      <div className="h-[120px] hidden lg:flex top-0 backdrop-blur-md w-full flex-row justify-center items-end fixed navbar bg-main z-40">
        <div className="w-[95vw] lg:w-[95vw] h-full flex flex-row justify-around items-center text-white">
          <div className="flex w-11/12 flex-row justify-between items-center border-1 px-8 py-5 rounded-[50px] box-shadow">
            <div
              className="text-xl font-[500] gap-4 flex flex-row justify-center items-center cursor-pointer"
              onClick={() => {
                navigate("/");
                window.scrollTo(0, 0);
              }}
            >
              <img src="/logo.png" className="w-[40px] h-[40px]" alt="" />
              <p className="otherSection">Sight Shark</p>
            </div>
            <div className="flex flex-row justify-around items-center gap-[40px]">
              {links?.map((e, i) => {
                return (
                  <p
                    key={i}
                    className={`${
                      location.pathname === e?.link
                        ? "text-bgBlue"
                        : "text-white"
                    } cursor-pointer otherSection`}
                    onClick={() => {
                      navigate(e?.link);
                      window.scrollTo(0, 0);
                    }}
                  >
                    {e?.title}
                  </p>
                );
              })}
            </div>
          </div>
          <div className="whitespace-nowrap ml-10 flex flex-row justify-end items-center text-white">
            <button
              className="bg-bgBlue text-white px-10 md:px-9 py-2 lg:py-5 rounded-[100px] text-[14px] lg:text-[1.15rem]"
              onClick={() => setContactUsModal(true)}
            >
              Contact us
            </button>
            <PopupModal
              url="https://calendly.com/rishabh-prowiz/30min"
              rootElement={document.getElementById("root")}
              open={contactUsModal}
              onModalClose={() => setContactUsModal(false)}
            />
          </div>
        </div>
      </div>
      {/* Mobile */}
      <div className="flex lg:hidden fixed top-0 left-0 backdrop-blur-md w-[100vw] flex-row justify-center items-end navbar bg-[#030021] z-40">
        <div className="w-[100vw] h-[80px] flex flex-row justify-around items-center text-white ">
          <div className="basis-[95%] flex flex-row justify-between items-center border-1 px-4 py-3 rounded-[50px] box-shadow ">
            <div
              className="text-xl font-[500] gap-4 flex flex-row justify-center items-center cursor-pointer"
              onClick={() => {
                navigate("/");
                window.scrollTo(0, 0);
              }}
            >
              <img src="/logo.png" className="w-[40px] h-[40px]" alt="" />
              <p className="text-[1rem] 2xl:text-[1.25rem]">Sight Shark</p>
            </div>
            <div className="">
              {isNavbarOpen ? (
                <AiOutlineClose
                  fontSize={"2rem"}
                  onClick={() => setIsNavbarOpen(!isNavbarOpen)}
                />
              ) : (
                <RxHamburgerMenu
                  fontSize={"2rem"}
                  onClick={() => setIsNavbarOpen(!isNavbarOpen)}
                />
              )}
            </div>
            <div
              className={`${
                isNavbarOpen ? "w-[100vw] " : "w-[0vw]"
              } fixed transition-all left-0 top-[80px] duration-200 ease-in-out mobile-sidenav rounded-e-md`}
            >
              {isNavbarOpen && (
                <div
                  className={`flex flex-col bg-[#030021] box-shadow justify-between h-screen-minus-100 pt-5 pb-10`}
                >
                  <div className="basis-[95%] flex flex-col justify-between items-center border-1 px-8 py-3 rounded-[50px] ">
                    <div className="w-[100%] flex flex-col justify-around items-center gap-[30px]">
                      {links?.map((e, i) => {
                        return (
                          <p
                            key={i}
                            className={`${
                              location.pathname === e?.link
                                ? "text-mainBlue"
                                : "text-white"
                            } cursor-pointer otherSection`}
                            onClick={() => {
                              navigate(e?.link);
                              window.scrollTo(0, 0);
                              setIsNavbarOpen(!isNavbarOpen);
                            }}
                          >
                            {e?.title}
                          </p>
                        );
                      })}
                    </div>
                  </div>
                  <div className="w-[100%] flex justify-center items-center text-white px-8">
                    <button
                      className="mainButton"
                      onClick={() => {
                        setContactUsModal(true);
                        setIsNavbarOpen(!isNavbarOpen);
                      }}
                    >
                      Contact us
                    </button>
                    <PopupModal
                      url="https://calendly.com/rishabh-prowiz/30min"
                      rootElement={document.getElementById("root")}
                      open={contactUsModal}
                      onModalClose={() => setContactUsModal(false)}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
