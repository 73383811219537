import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import React, { useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import CTA from "../Components/CTA";
import { RiPencilLine } from "react-icons/ri";
import { IoColorPaletteOutline } from "react-icons/io5";
import { FaBoltLightning, FaPlay } from "react-icons/fa6";
import { MdContentCopy, MdDownload } from "react-icons/md";
import { CiShare2 } from "react-icons/ci";
import { Link, useNavigate, useParams } from "react-router-dom";
import LandingSection from "../Components/Utils/LandingSection";
import {
  BsChevronCompactDown,
  BsChevronCompactUp,
  BsChevronLeft,
  BsChevronRight,
} from "react-icons/bs";

function IndividualReport({ reports }) {
  const { idName } = useParams();
  // const report = reports[id];
  const [report, setReport] = useState();
  const [contactUsModal, setContactUsModal] = useState(false);
  const [id, setId] = useState(0);
  const [selectedImg, setSelectedImg] = useState(0);
  const [openIndex, setOpenIndex] = useState(0);
  const [buttons, setButtons] = useState({ left: false, right: false });
  const navigate = useNavigate();

  const logo = [
    {
      img: "/Dashboards/Icons/looker-icon-svgrepo-com.svg",
    },
    {
      img: "/Dashboards/Icons/power-bi-icon.svg",
    },
    {
      img: "/Dashboards/Icons/tableau-icon-svgrepo-com.svg",
    },
  ];

  useEffect(() => {
    let data = reports?.find(
      (e) =>
        e?.title?.toLowerCase()?.replaceAll(" ", "-")?.replaceAll(",", "") ===
        idName
    );
    let idx = reports?.findIndex(
      (e) =>
        e?.title?.toLowerCase()?.replaceAll(" ", "-")?.replaceAll(",", "") ===
        idName
    );
    // setCaseStudy(data);
    setReport(data);
    setId(idx);
  }, [idName]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="w-full bg-[#030021] pt-[70px] lg:pt-[120px]">
      <Navbar />
      <LandingSection heading={report?.title} text={report?.text} />
      <div className="w-full">
        <div className="flex lg:flex-row flex-col-reverse items-start justify-between w-[95vw] lg:w-[85vw] pt-5 mx-auto p-3">
          <div className="lg:w-8/12 w-full flex items-center justify-center">
            <BsChevronLeft
              onClick={(e) => {
                if (openIndex == 0) {
                  setSelectedImg(report?.headings?.length - 1);
                  setOpenIndex(report?.headings?.length - 1);
                } else {
                  setOpenIndex(openIndex - 1);
                  setSelectedImg(selectedImg - 1);
                }
              }}
              className="text-3xl text-white"
            />
            <img
              src={report?.headings[selectedImg].images}
              alt={report?.headings[selectedImg].images.src}
              className="rounded-lg w-10/12 lg:w-full"
              width={1000}
              height={1000}
            />
            <BsChevronRight
              onClick={(e) => {
                if (openIndex == report?.headings.length - 1) {
                  setSelectedImg(0);
                  setOpenIndex(0);
                } else {
                  setOpenIndex(openIndex + 1);
                  setSelectedImg(selectedImg + 1);
                }
              }}
              className="text-3xl text-white"
            />
          </div>
          <div className="lg:w-4/12 w-full lg:ml-10 text-white">
            <div className="w-full lg:mb-0 mb-5">
              <p className="subSectionHeading mb-2 font-semibold">
                Dashboard Pages{" "}
              </p>
              <div className="border border-gray-500/30 rounded-md p-2">
                {report?.headings.map((e, i) => {
                  return (
                    <Block
                      key={i}
                      setSelectedImg={setSelectedImg}
                      index={i}
                      total={report?.headings?.length}
                      isOpen={openIndex === i}
                      setOpenIndex={() =>
                        setOpenIndex(openIndex === i ? null : i)
                      }
                      data={e}
                    />
                  );
                })}
              </div>
            </div>
            <div className="lg:block hidden">
              <Container logo={logo} report={report} />
            </div>
          </div>
        </div>
        <div className="text-white lg:hidden w-[90vw] mx-auto">
          <Container logo={logo} report={report} id={id} />
        </div>
        <div className="w-full lg:w-[95vw] mx-auto flex items-center justify-between mt-[5vh] px-2">
          <button
            className="animatedButton subSection"
            onClick={(e) => {
              if (id == 0) {
                let index = reports[reports?.length - 1]?.title
                  ?.toLowerCase()
                  ?.replaceAll(" ", "-")
                  ?.replaceAll(",", "");
                navigate(`/reports/${index}`);
              } else {
                let index = reports[parseInt(id) - 1]?.title
                  ?.toLowerCase()
                  ?.replaceAll(" ", "-")
                  ?.replaceAll(",", "");
                navigate(`/reports/${index}`);
              }
            }}
            onMouseEnter={(e) => {
              setButtons({ right: false, left: true });
            }}
            onMouseLeave={(e) => {
              setButtons({ right: false, left: false });
            }}
          >
            Previous
          </button>
          <button
            className="animatedButton subSection"
            onClick={(e) => {
              if (id == reports?.length - 1) {
                let index = reports[0]?.title
                  ?.toLowerCase()
                  ?.replaceAll(" ", "-")
                  ?.replaceAll(",", "");
                navigate(`/reports/${index}`);
              } else {
                let index = reports[parseInt(id) + 1]?.title
                  ?.toLowerCase()
                  ?.replaceAll(" ", "-")
                  ?.replaceAll(",", "");
                navigate(`/reports/${index}`);
              }
            }}
            onMouseEnter={(e) => {
              setButtons({ left: false, right: true });
            }}
            onMouseLeave={(e) => {
              setButtons({ left: false, right: false });
            }}
          >
            Next
          </button>
        </div>
        <div className="w-full lg:w-[95vw] lg:flex hidden mx-auto items-center justify-between mt-3 px-2 text-white">
          <p className={`${buttons?.left ? "opacity-100 w-fit" : "opacity-0"}`}>
            {id == 0
              ? reports[reports?.length - 1]?.title
              : reports[parseInt(id) - 1]?.title}
          </p>
          <p
            className={`${buttons?.right ? "opacity-100 w-fit" : "opacity-0"}`}
          >
            {id == reports?.length - 1
              ? reports[0]?.title
              : reports[parseInt(id) + 1]?.title}
          </p>
        </div>
      </div>
      <div className="-mt-20">
        <CTA
          heading={"Still have questions?"}
          button={"Contact Us"}
          onClick={() => setContactUsModal(true)}
          setContactUsModal={setContactUsModal}
          contactUsModal={contactUsModal}
        />
      </div>
      <Footer />
    </div>
  );
}

const Block = ({
  index,
  setSelectedImg,
  total,
  isOpen,
  setOpenIndex,
  data,
}) => {
  return (
    <div
      onClick={(e) => {
        setOpenIndex();
        setSelectedImg(index);
      }}
      className={`text-grey hover:text-white cursor-pointer mb-1 transition-all ${
        isOpen ? "bg-gray-900/80 rounded-lg" : "bg-transparent"
      }`}
    >
      <div
        className={`flex reportHeading px-2 lg:px-3 pt-2 pb-1 ${
          !isOpen && index !== total - 1 ? "border-b border-b-gray-500/30" : ""
        } items-center justify-between w-full`}
      >
        <h1>{data?.title}</h1>
        {!isOpen ? <BsChevronCompactDown /> : <BsChevronCompactUp />}
      </div>
      {isOpen && <p className="px-3 lg:px-4 pb-3 reportText">{data?.text}</p>}
    </div>
  );
};

const Container = ({ logo, report }) => {
  const [idName, setIdName] = useState("");
  useEffect(() => {
    let data = report?.title
      ?.toLowerCase()
      ?.replaceAll(" ", "-")
      ?.replaceAll(",", "");
    setIdName(data);
  }, [report]);

  return (
    <div>
      <div className="my-5 lg:my-10 ml-1 flex lg:items-start gap-x-5">
        <div>
          <p className="subSectionHeading font-semibold">Visualization </p>
          <div className="flex items-center mt-3">
            {logo.map((e, i) => {
              return (
                <img
                  src={e?.img}
                  key={i}
                  alt="Amzon S3"
                  className="mr-2 lg:mr-5 p-1.5 lg:p-2 bg-gray-500/50 w-[10vw] lg:w-[3vw] rounded-md lg:rounded-xl cursor-pointer"
                />
              );
            })}
          </div>
        </div>{" "}
        <div>
          <p className="subSectionHeading font-semibold">Data Source </p>
          <div className="flex items-center mt-3">
            <img
              src={report?.logo}
              alt="Amazon"
              className="mr-2 lg:mr-3 p-1.5 lg:p-2 bg-gray-500/50 w-[10vw] lg:w-[3vw] rounded-md lg:rounded-xl cursor-pointer"
            />
            <span className="font-light reportHeading">
              {report?.title.split(" ")[0]}
            </span>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 my-5 lg:my-10 px-1 lg:px-2 gap-y-1 lg:gap-y-2 gap-x-1 lg:gap-x-3 border py-1.5 rounded-md border-gray-500/30">
        {[
          {
            icon: (
              <RiPencilLine
                size={window.innerWidth < 600 ? 30 : 35}
                className="p-1.5 bg-gray-700/60 lg:bg-gray-700 rounded-md lg:rounded-lg"
              />
            ),
            title: "Instant edit access",
          },
          {
            icon: (
              <IoColorPaletteOutline
                size={window.innerWidth < 600 ? 30 : 35}
                className="p-1.5 bg-gray-700/60 lg:bg-gray-700 rounded-md lg:rounded-lg"
              />
            ),
            title: "Custom logo & colors",
          },
          {
            icon: (
              <FaBoltLightning
                size={window.innerWidth < 600 ? 30 : 35}
                className="p-1.5 bg-gray-700/60 lg:bg-gray-700 rounded-md lg:rounded-lg"
              />
            ),
            title: "Quick setup",
          },
          {
            icon: (
              <MdContentCopy
                size={window.innerWidth < 600 ? 30 : 35}
                className="p-1.5 bg-gray-700/60 lg:bg-gray-700 rounded-md lg:rounded-lg"
              />
            ),
            title: "Unlimited versions",
          },
          {
            icon: (
              <CiShare2
                size={window.innerWidth < 600 ? 30 : 35}
                className="p-1.5 bg-gray-700/60 lg:bg-gray-700 rounded-md lg:rounded-lg"
              />
            ),
            title: "Sharing options",
          },
          {
            icon: (
              <MdDownload
                size={window.innerWidth < 600 ? 30 : 35}
                className="p-1.5 bg-gray-700/60 lg:bg-gray-700 rounded-md lg:rounded-lg"
              />
            ),
            title: "Downloadable data",
          },
        ].map((e, i) => {
          return (
            <div
              key={i}
              className="flex items-center cursor-pointer hover:bg-gray-900 p-2 rounded-md lg:rounded-lg"
            >
              {e?.icon}
              <p className="ml-3 reportText">{e?.title}</p>
            </div>
          );
        })}
      </div>{" "}
      <div className="flex my-5 lg:my-10 lg:justify-start justify-center items-center">
        <Link to={`/reports/${idName}/view-dashboard`} target="_blank">
          <button className="viewDemoButton flex items-center">
            <FaPlay className="mr-2 text-xl lg:text-3xl" />
            <p className="reportText">View Dashboard</p>
          </button>
        </Link>
      </div>
    </div>
  );
};

export default IndividualReport;
