import React, { useState } from "react";
import Navbar from "../Components/Navbar";
import Dashboards from "../Components/Dashboards";
import Footer from "../Components/Footer";
import LandingSection from "../Components/Utils/LandingSection";

function Reports({ reports }) {
  const [filter, setFilter] = useState("All");

  return (
    <div className="w-full h-[auto] bg-[#030021] pt-[70px] lg:pt-[120px]">
      <Navbar />
      <LandingSection
        heading={"Dashboard design templates & Inspirations"}
        text="Explore dashboard design templates from some of our most popular
            integrations!"
      />
      <div className="w-[90vw] lg:w-[85vw] mb-5 lg:mb-10 mx-auto flex items-center justify-center text-white">
        {["All", "E-Commerce", "Marketing & Social Media"].map((e, i) => {
          return (
            <p
              key={i}
              className={`border px-3 lg:px-8 rounded-full mr-2 lg:mr-5 hover:bg-[#640DFB] hover:border-transparent hover:text-white ${
                e === filter
                  ? "text-white  bg-[#640DFB] border-transparent"
                  : ""
              } transition-all whitespace-nowrap text-xs lg:text-lg py-2 cursor-pointer`}
              onClick={() => {
                setFilter(e);
              }}
            >
              {e}
            </p>
          );
        })}
      </div>
      <div className="w-full flex flex-row justify-center items-center mb-[5vh] lg:mb-[15vh]">
        <Dashboards reports={reports} filter={filter} />
      </div>
      <Footer />
    </div>
  );
}

export default Reports;
