import React, { useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { useParams } from "react-router-dom";
import CTA from "../Components/CTA";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import { useCountUp } from "react-countup";

export const case_studies = [
  {
    title: "Marketing Analytics, Automated Insights",
    image:
      "https://img.freepik.com/free-photo/corporate-management-strategy-solution-branding-concept_53876-167088.jpg?t=st=1721827324~exp=1721830924~hmac=23c37c82185a35c47f38b59921ac298e1c9a855b73fc3982d0061c67eb8ea80d&w=1380",

    tags: "Marketing Analytics |  Automated Reporting | Looker Studio",
    headline:
      "Streamlining Client reporting and Data Pipelines for a Marketing Agency.",
    breif:
      "Discover streamlined client reporting with a premier Australian Marketing Agency in luxury real estate. Leveraging Google Looker Studio, we optimized data extraction and dynamic visualization across diverse platforms for comprehensive campaign insights.",
    challenge:
      "The challenge was in addressing the unique needs of a marketing agency with a substantial client base. Based out of Australia, the agency operates in the large luxury real estate sector and manages monthly reporting for over 50 clients, demanding comprehensive data retrieval from diverse sources. The data, sourced from various platforms, was not centralized, making manual collection and report creation both time-consuming and complex. A scalable solution was imperative to meet the client's reporting needs effectively.",
    impact: [
      "Streamlined Reporting System: Our holistic approach led to a highly efficient and automated reporting system, drastically reducing the time and effort needed for monthly campaign performance reporting.",
      "Centralized and Secure Data Infrastructure: Establishing a centralized and secure data infrastructure improved overall efficiency and guaranteed precise and confidential reporting for each client. This significantly strengthened client relationships.",
      "Dynamic Reporting Templates: We introduced dynamic reporting templates, a key element in enhancing the reporting experience. This innovative addition allowed for personalized and insightful reports, contributing to an overall improved client-agency interaction.",
      "User-Friendly Portal: The portal provided easy access to dashboard links for each client, fostering a seamless and interactive experience for the marketing agency. This accessibility contributed to a more engaging and collaborative relationship.",
      "Cost Savings through Innovation: We achieved substantial cost savings by steering away from traditional methods and automating the reporting process. This innovative approach enhanced efficiency and proved to be a financially prudent choice for the marketing agency.",
    ],
    home: "/analytics.png",
    impact_in_numbers: [
      {
        number: 10,
        description: "data sources",
      },
      {
        number: 4,
        description: "years of daily historical data",
      },
      {
        number: 48,
        description: "hours time to deployment",
      },
      {
        number: 90,
        description: "reduction in reporting effort",
      },
    ],
  },
  {
    title: "Optimizing Marketing Spend with Advanced Analytics",
    tags: "Marketing Analytics | Campaign Optimization | Google Looker Studio",
    home: "/marketing.png",
    image: "Casestudies/stock4.jpeg",
    headline:
      "Maximizing ROI Through Data-Driven Insights from Taboola and Outbrain Campaigns",
    breif:
      "Dive into the strategic analytical advancements of a company seeking to refine its marketing campaigns through comprehensive insights into advertising spend on Taboola and Outbrain, alongside revenue and cost data from their MySQL database. By harnessing the power of Google Looker Studio, the company set out to optimize its marketing efforts for maximum ROI and campaign effectiveness.",
    challenge:
      "The primary challenge revolved around amalgamating disparate data sources—Taboola, Outbrain, and a MySQL database—into a singular analytical framework to glean actionable insights. The company aimed to dissect its marketing spend effectiveness, understanding the intricate balance between expenditure, website revenue, and associated costs. Achieving a granular view of campaign performance to fine-tune marketing strategies was essential.",
    impact: [
      "ROI Enhancement: By analyzing the interplay between marketing spend and revenue, we identified key areas for optimization, significantly increasing marketing ROI.",
      "Campaign Efficiency: Insights into campaign performance allowed for refined targeting and spend allocation, boosting overall efficiency.",
      "Strategic Decision-Making: The actionable intelligence provided by our dashboards informed strategic marketing decisions, enhancing campaign outcomes and business profitability.",
      "Holistic View of Marketing Spend: The unified analytics platform offered a holistic overview of all marketing activities, enabling a synergistic approach to campaign management and optimization",
    ],
    impact_in_numbers: [
      {
        number: 5,
        description: "data sources",
      },
      {
        number: 15,
        description: "Websites",
      },
      {
        number: 5,
        description: "Records",
      },
      {
        number: 0,
        description: "Manual Effort",
      },
    ],
  },
  {
    title: "Navigating the Digital Hospitality with Comprehensive Analytics",
    tags: "Social Media Analytics | Google BigQuery | Google Looker Studio",
    image:
      "https://img.freepik.com/free-photo/futuristic-landscape-dubai_23-2151339806.jpg?t=st=1721827361~exp=1721830961~hmac=a0c9b849d769e723e142a72453ef5219f0da7823b6d58770d195477926811d99&w=1380",
    home: "/hotel.png",
    headline:
      "Elevating Hotel Digital Presence through Unified Social Media Analytics",
    breif:
      "Discover how a leading full-service brand, sales, and marketing firm, specializing in the hospitality sector, mastered its digital presence across multiple platforms. With a focus on design thinking and data depth, the brand leveraged advanced analytics to streamline its digital marketing efforts, encompassing Facebook, Google, LinkedIn, and Instagram.",
    challenge:
      "The firm aimed to dissect and harness its social media analytics and broader digital data to refine its online presence and marketing strategies. The challenge was multifaceted: aggregating disparate data streams from Facebook Ads, Google Ads, Facebook Insights, Instagram Insights, LinkedIn, Programmatic advertising, and Google Sheets into a singular, coherent analytical viewpoint. The diversity of data formats and the need for real-time insights made this a complex endeavor.",
    impact: [
      "Enhanced Digital Strategy: The unified analytics framework enabled a granular understanding of digital engagements and the effectiveness of marketing campaigns, leading to a refined strategy with a focus on high-ROI activities.",
      "Operational Efficiency: Streamlining data processes and centralising analytics tools significantly reduced manual efforts and time spent on data management.",
      "Data-Driven Decision Making: Access to real-time, comprehensive insights across all digital channels empowered the firm to make informed decisions, optimising their marketing efforts for better engagement and returns.",
    ],
    impact_in_numbers: [
      {
        number: 8,
        description: "data sources",
      },
      {
        number: 4,
        description: "Countries(currency)",
      },
      {
        number: 48,
        description: "hours time to deployment",
      },
      {
        number: 90,
        description: "reduction in reporting effort",
      },
    ],
  },
  {
    title: "Transforming a E-Commerce business with Data-Driven Insights",
    tags: "E-Commerce Analytics | Cohort Analysis | Google Looker Studio",
    home: "/e-commerce.png",
    image:
      "https://img.freepik.com/free-photo/front-view-cyber-monday-shopping-cart-with-bags-copy-space_23-2148657638.jpg?t=st=1721827367~exp=1721830967~hmac=d0514844ed3e877a455b92687fc4836b3a5e486854018901f4c0015bef2f5fba&w=1380",
    headline:
      "Synergizing Multi-Store E-Commerce Insights for Strategic Growth",
    breif:
      "Explore the analytical transformation of an e-commerce merchandise company with over 25 Shopify stores, specializing in both subscription-based and one-time product offerings. Leveraging Google Looker Studio, the company embarked on an insightful journey to understand customer behavior, subscription dynamics, and logistic efficiencies, enhancing overall operational and marketing strategies",
    challenge:
      "The company faced the daunting task of aggregating and analyzing data from over 25 distinct Shopify stores. Their diversified product range, encompassing both subscription-based and one-time purchase items, added layers of complexity to understanding customer behaviors, subscription dynamics, and logistic efficiencies. The critical challenge lay in synthesizing this multifaceted data to draw actionable insights for strategic decision-making.",
    impact: [
      "Strategic Subscription Insights: Our analysis illuminated subscription behaviors, enabling targeted strategies to boost retention and acquisition.",
      "Logistics Optimization: By identifying inefficiencies in shipping processes, we guided the company towards a 15% reduction in logistics expenses.",
      "Enhanced Customer Engagement: Insights into customer purchasing behaviors informed strategies to enhance lifetime value, driving a more personalized shopping experience.",
      "Data-Driven Decision Making: The cohesive analytics environment empowered the company with real-time insights, fostering agile and informed strategic directions.",
    ],
    impact_in_numbers: [
      {
        number: 25,
        description: "data sources",
      },
      {
        number: 5,
        description: "Records",
      },
      {
        number: 40000,
        description: "subscriptions",
      },
      {
        number: 100,
        description: "automated reports",
      },
    ],
  },
];

function IndividualCase() {
  let { id } = useParams();
  const [contactUsModal, setContactUsModal] = useState(false);
  const [caseStudy, setCaseStudy] = useState();

  useEffect(() => {
    let data = case_studies?.find(
      (e) =>
        e?.title?.toLowerCase()?.replaceAll(" ", "-")?.replaceAll(",", "") ===
        id
    );
    setCaseStudy(data);
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="w-full h-[auto] bg-[#030021] pt-[20px] md:pt-[120px]">
      <Navbar />
      <div className="backgroundglitter relative w-full h-[100vh] md:h-[90vh] flex flex-col-reverse md:flex-row justify-center px-[10vw] items-center">
        <div className="flex flex-col text-white items-start justify-start md:w-6/12 md:mt-0 mt-4 gap-2 md:gap-4">
          <h1 className="mainHead text-center md:text-start">
            {caseStudy?.title}
          </h1>
          <p className="text-[#CACACA] text-center md:text-start otherSection w-full md:w-7/12">
            {caseStudy?.tags}
          </p>
        </div>
        <img
          src={caseStudy?.home ? caseStudy?.home : "/case-study.png"}
          alt=""
          className="md:w-[45%] lg:w-8/12 md:mt-10 mx-auto object-cover rounded-2xl"
        />
      </div>
      <div className="flex flex-col w-full justify-center items-center text-white">
        <div className="w-[90vw] md:w-[80vw] flex flex-col justify-start items-start gap-1 md:gap-3">
          <h2 className="otherSectionHeading mt-8 font-[500]">Brief</h2>
          <p className="otherSection">{caseStudy?.breif}</p>
          <h2 className="otherSectionHeading mt-8 font-[500]">
            Project Challenge
          </h2>
          <p className="otherSection">{caseStudy?.challenge}</p>
          <div className="w-full md:w-[80vw] flex flex-col justify-center items-center my-8 rounded-[10px]">
            <div className="flex flex-row justify-start items-start w-full">
              <p className="otherSectionHeading font-[500]">
                Impact in Numbers
              </p>
            </div>
            <div className="w-full grid grid-cols-2 md:flex md:flex-row flex-wrap md:justify-between items-center">
              {caseStudy?.impact_in_numbers.reduce((acc, e, i, arr) => {
                acc.push(
                  <div key={i} className="flex items-center justify-center">
                    <Block data={e} />
                  </div>
                );
                if (i != arr?.length - 1) {
                  acc.push(
                    <div className="w-[1px] md:block hidden h-[8vw] bg-white/50"></div>
                  );
                }
                return acc;
              }, [])}
            </div>
          </div>
          <h2 className="otherSectionHeading font-[500]">Impact</h2>
          <ol className="list-disc ml-5 md:ml-8 space-y-4 otherSection">
            {caseStudy?.impact.map((item) => {
              return <li>{item}</li>;
            })}
          </ol>
        </div>
        <CTA
          heading={"Still have questions?"}
          button={"Contact Us"}
          onClick={() => setContactUsModal(true)}
          setContactUsModal={setContactUsModal}
          contactUsModal={contactUsModal}
        />
      </div>
      <Footer />
    </div>
  );
}

const Block = ({ data }) => {
  const countUpRef = React.useRef(null);
  const { start, pauseResume, reset, update } = useCountUp({
    ref: countUpRef,
    start: 0,
    end: data?.number,
    delay: 0,
  });

  gsap.registerPlugin(ScrollTrigger);
  useEffect(() => {
    pauseResume();
    ScrollTrigger.create({
      trigger: countUpRef.current,
      start: "top 90%",
      end: "top 20%",
      onEnter: () => start(),
      onLeave: () => reset(),
      onEnterBack: () => start(),
      onLeaveBack: () => reset(),
    });
  }, []);

  return (
    <div className="flex flex-col px-3 md:px-0 items-center md:items-start justify-center md:my-0 my-5 w-full md:w-[12vw] md:aspect-square">
      <div
        className={`cursor-pointer mainHead text-white flex ${
          data?.description.toLowerCase().includes("records")
            ? "items-end"
            : "items-center"
        }`}
      >
        <div ref={countUpRef} />
        {data?.description
          .toLowerCase()
          .includes("Reduction In Reporting Effort".toLowerCase()) ? (
          <p className="otherSectionHeading ml-0.5">%</p>
        ) : data?.description
            .toLowerCase()
            .includes("records".toLowerCase()) ? (
          <p className="otherSectionHeading ml-0.5 mb-2">MN+</p>
        ) : (
          data?.number != 0 && <p className="otherSectionHeading ml-0.5">+</p>
        )}
      </div>
      <h1 className="otherSection md:font-medium md:text-start text-center md:whitespace-nowrap mt-1 md:mt-2 capitalize text-white">
        {data?.description}
      </h1>
    </div>
  );
};

export default IndividualCase;
